
import { Paths } from '@/router/Paths';
import Button from '@/components/Button/Button.vue';
import firebase from 'firebase';
import { defineComponent } from 'vue';

export default defineComponent({
  components: { Button },
  setup() {
    return {
      Paths,
    };
  },
  created() {
    firebase.analytics().logEvent('user_on_private');
  },
  methods: {
    logEvent(): void {
      firebase.analytics().logEvent('user_proceed_to_private');
    },
  },
});
